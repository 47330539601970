import { format } from "date-fns";
// import FileTypeView from "./FileTypeViews.vue";
export const tableColumns = {
  name: {
    formatDataKey: "Title",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    colWidth: 700,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  // type: {
  //   formatDataKey: "File Type",
  //   formatDataValue: (arg: string) => arg,
  //   allowInAdvancedFilter: true,
  //   allowInTableOptions: true,
  //   visibleByDefault: true,
  //   allowSort: true,
  //   formatDataComponent: FileTypeView,
  //   position: 1
  // },
  isPublic: {
    formatDataKey: "Is Public",
    formatDataValue: (arg: string) => (arg ? "Yes" : "No"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 100,
    simpleFilter: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" }
    ],
    allowInSimpleFilter: true,
    position: 1
  },
  createdOn: {
    formatDataKey: "Created",
    formatDataValue: (arg: string) => format(new Date(arg), "yyyy-M-d"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2
  },
  category: {
    formatDataKey: "Category",
    formatDataValue: (arg: any) => (arg ? arg : "None"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 3
  },
  "createdByData.fullName": {
    formatDataKey: "Created By",
    formatDataValue: (arg: any) => (arg ? arg : "None"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: false,
    allowSort: false,
    allowInSimpleFilter: true,
    position: 4,
    colWidth: 200
  }
};

export const agentsTableColumns = {
  name: {
    formatDataKey: "Title",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  category: {
    formatDataKey: "Category",
    formatDataValue: (arg: any) => (arg ? arg : "None"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 1
  }
};
